import React, { useContext, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { AuthContext } from "../auth/authProvider";
import assets from "../common/assets";
import colors from "../common/colors";
import extractValue from "../common/helpers/extractValue";
import mixins from "../common/mixins";
import { breakpoint, dimension, fontSize, space } from "../common/variables";
import Container from "../components/Container";
import { UserContext } from "../contexts/UserContext";
import { NavigationItem } from "../views/navigationItems";
import Button from "./Button";
import DropdownMenu from "./DropdownMenu";
import Navigation from "./Navigation";

interface Props {
    navigation: NavigationItem[];
    blur?: boolean;
}

const Header = (props: Props) => {
    const { signout } = useContext(AuthContext);
    const [navVisible, setNavVisible] = useState(true);
    const { authUserData } = useContext(UserContext);

    useLayoutEffect(() => {
        let prevScroll = window.pageYOffset;

        function handleScroll() {
            if (window.pageYOffset < extractValue(dimension.navHeight) / 1.5) {
                setNavVisible(true);
                return;
            }

            if (prevScroll > window.pageYOffset) {
                setNavVisible(true);
            } else {
                setNavVisible(false);
            }

            prevScroll = window.pageYOffset;
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const municipalityName = authUserData?.municipalityName;

    return (
        <>
            <Spacer navItems={props.navigation.length} />
            <Wrapper blur={props.blur ? props.blur : undefined}>
                <Container>
                    <Content>
                        <Link to="/">
                            <Logo src={assets.logo} />
                        </Link>

                        {authUserData && (
                            <Title>{`${
                                municipalityName || "Ombudshandel"
                            }`}</Title>
                        )}
                        <DropdownMenu
                            headerContent={
                                authUserData && (
                                    <Menu>
                                        <Icon>{assets.user}</Icon>
                                        <MenuTitle>
                                            {authUserData.firstName.length
                                                ? authUserData.firstName
                                                : "Mitt konto"}
                                        </MenuTitle>
                                    </Menu>
                                )
                            }>
                            <Button
                                size="small"
                                theme="inverted"
                                onClick={() => signout()}
                                title="Logga ut"
                            />
                        </DropdownMenu>
                    </Content>
                </Container>
                {props.navigation.length > 1 && (
                    <Navigation items={props.navigation} visible={navVisible} />
                )}
            </Wrapper>
        </>
    );
};

export default Header;

const Spacer = styled.div<{ navItems: number }>`
    height: ${dimension.headerHeight};
    padding-top: ${({ navItems }) =>
        css`
            ${extractValue(dimension.headerHeight) +
            extractValue(navItems > 1 ? dimension.navHeight : "0px") +
            extractValue(space.paddingSmall)}px
        `};
    @media ${breakpoint.medium} {
        padding-top: ${({ navItems }) =>
            css`
                ${extractValue(dimension.headerHeight) +
                extractValue(navItems > 1 ? dimension.navHeight : "0px") +
                extractValue(space.padding)}px
            `};
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled.header`
    display: flex;
    background-color: ${colors.white};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${colors.border};
    z-index: 9;
    transition: filter ${mixins.transitions.large};
    filter: ${({ blur }: { blur?: boolean }) => (blur ? "blur(4px)" : "none")};
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: ${dimension.headerHeight};
    background-color: ${colors.white};
    z-index: 1;
`;

const Logo = styled.img`
    height: 40px;
`;

const Title = styled.h1`
    font-size: ${fontSize.large};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    display: inline-block;
    animation: ${fadeIn} ${mixins.transitions.large} forwards;
`;

const Menu = styled.div`
    display: flex;
    animation: ${fadeIn} ${mixins.transitions.large} forwards;
    align-items: center;
`;

const MenuTitle = styled.span`
    margin-left: ${space.xSmall};
    font-size: ${fontSize.small};
`;

const Icon = styled.span`
    display: inline-block;
    fill: ${colors.black};
    height: 20px;
    width: 20px;
`;
