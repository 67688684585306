import { DevTool } from "@hookform/devtools";
import React from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { formatTimeRestrictionDate } from "../../common/helpers/formatTimeRestrictionDate";
import { PaymentTypes } from "../../common/translations";
import { ListOption, TimeRestriction } from "../../common/types";
import { getValidationResultFromFormError } from "../../common/validations";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DropdownList from "../../components/DropdownList";
import { FormField, FormFieldSet } from "../../components/FormField";
import { CellHeading } from "../../components/Grid/Cell";
import TextInput from "../../components/TextInput";
import { CustomerFormData } from "./CustomerView.hooks";
import { isInvoicePayment } from "./customerDetailsConfig";
import { Customer, isCustomerMatkontoPayment } from "./customerTypes";

interface Props {
    form: UseFormReturn<CustomerFormData>;
    availablePaymentMethods?: ListOption<PaymentTypes>[];
    availableTimeFrames?: TimeRestriction[];
    availableUnits?: ListOption[];
    isNewForm?: boolean;
    lastSavedDetails?: Customer;
    onMatkontoInitialize?: () => void;
    onMatkontoDelete?: (id: string) => void;
    enableFormDevTool: boolean;
}

const getTimeslotRestrictionsListOptions = (
    timeRestrictions?: TimeRestriction[]
): ListOption<string, string, TimeRestriction>[] | undefined => {
    if (!timeRestrictions?.length) {
        return undefined;
    }

    return timeRestrictions.map((restriction) => ({
        id: formatTimeRestrictionDate(restriction).value,
        name: formatTimeRestrictionDate(restriction).value,
        extraData: restriction,
    }));
};

const CustomerDeliveryForm = (props: Props) => {
    const {
        form,
        availableUnits,
        isNewForm,
        availablePaymentMethods,
        availableTimeFrames,
        onMatkontoInitialize,
        onMatkontoDelete,
        lastSavedDetails,
    } = props;

    const watchSelfOrder = useWatch({
        control: form.control,
        name: "selfOrder",
    });

    const watchPaymentInfoId = useWatch({
        control: form.control,
        name: "paymentInfoId",
    });

    const watchEmailInvoice = useWatch({
        control: form.control,
        name: "billing.emailInvoice",
    });

    return (
        <>
            <FormField>
                <Controller
                    name="unit"
                    control={form.control}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState,
                    }) => (
                        <DropdownList
                            data={availableUnits || []}
                            value={value}
                            type="single"
                            getValueFormatted={(v) => v.name}
                            setChoice={(option) => {
                                onChange(option);
                                onBlur();
                                form.resetField("timeRestrictions", {
                                    defaultValue: [],
                                    keepError: true,
                                });
                            }}
                            placeholder="Enhet"
                            required
                            validation={getValidationResultFromFormError(
                                fieldState
                            )}
                        />
                    )}
                />
            </FormField>
            <FormField>
                <Controller
                    name="timeRestrictions"
                    control={form.control}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState,
                    }) => (
                        <DropdownList
                            data={
                                getTimeslotRestrictionsListOptions(
                                    availableTimeFrames
                                ) || []
                            }
                            type="multiple"
                            value={value}
                            getValueFormatted={(v) =>
                                v.length > 1
                                    ? "Fler tider valda"
                                    : v.length === 0
                                    ? ""
                                    : v[0].name
                            }
                            setChoice={(option) => {
                                onBlur();
                                onChange(option);
                            }}
                            placeholder="Leveranstid"
                            required
                            emptyText={
                                value
                                    ? "Det finns inga tillgängliga leveranstider."
                                    : "Välj en enhet för att se tillgängliga leveranstider."
                            }
                            validation={getValidationResultFromFormError(
                                fieldState
                            )}
                        />
                    )}
                />
            </FormField>
            <FormField>
                <Controller
                    name="defaultDriverMessage"
                    control={form.control}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState,
                    }) => (
                        <TextInput
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            autoComplete="on"
                            multiline
                            placeholder="Kommentar till chaufför"
                            validation={getValidationResultFromFormError(
                                fieldState
                            )}
                        />
                    )}
                />
            </FormField>
            <FormField>
                <Controller
                    name="medmeraId"
                    control={form.control}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState,
                    }) => (
                        <TextInput
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            autoComplete="on"
                            placeholder="Medmera-ID"
                            validation={getValidationResultFromFormError(
                                fieldState
                            )}
                        />
                    )}
                />
            </FormField>
            {!!availablePaymentMethods?.length && (
                <FormField>
                    <Controller
                        name="paymentInfoId"
                        control={form.control}
                        render={({
                            field: { onChange, onBlur, value },
                            fieldState,
                        }) => (
                            <DropdownList
                                data={availablePaymentMethods}
                                value={availablePaymentMethods.find(
                                    (item) => item.id === value
                                )}
                                type="single"
                                setChoice={(v) => {
                                    onBlur();
                                    onChange(v.id);
                                }}
                                getValueFormatted={(v) => v.name}
                                placeholder="Betalsätt"
                                required
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            )}

            {isInvoicePayment(watchPaymentInfoId) && (
                <>
                    <FormField>
                        <Controller
                            name="socialSecurityNumber"
                            control={form.control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState,
                            }) => (
                                <TextInput
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    autoComplete="on"
                                    required={
                                        isNewForm ||
                                        !!lastSavedDetails?.socialSecurityNumber ||
                                        !isInvoicePayment(
                                            lastSavedDetails?.paymentInfo?.id
                                        )
                                    }
                                    placeholder="Personnummer"
                                    validation={getValidationResultFromFormError(
                                        fieldState
                                    )}
                                />
                            )}
                        />
                    </FormField>
                    <FormFieldSet>
                        <Controller
                            name="billing.billingFirstName"
                            control={form.control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState,
                            }) => (
                                <FormField halfSize>
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        autoComplete="on"
                                        required
                                        placeholder="Förnamn"
                                        validation={getValidationResultFromFormError(
                                            fieldState
                                        )}
                                    />
                                </FormField>
                            )}
                        />
                        <FormField halfSize>
                            <Controller
                                name="billing.billingLastName"
                                control={form.control}
                                render={({
                                    field: { onChange, onBlur, value },
                                    fieldState,
                                }) => (
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        autoComplete="on"
                                        required
                                        placeholder="Efternamn"
                                        validation={getValidationResultFromFormError(
                                            fieldState
                                        )}
                                    />
                                )}
                            />
                        </FormField>
                    </FormFieldSet>
                    <FormField>
                        <Controller
                            name="billing.billingReference"
                            control={form.control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState,
                            }) => (
                                <TextInput
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    autoComplete="on"
                                    placeholder="Referens"
                                    validation={getValidationResultFromFormError(
                                        fieldState
                                    )}
                                />
                            )}
                        />
                    </FormField>
                    <FormField>
                        <Controller
                            name="billing.billingLine2"
                            control={form.control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState,
                            }) => (
                                <TextInput
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    autoComplete="on"
                                    placeholder="C/O"
                                    validation={getValidationResultFromFormError(
                                        fieldState
                                    )}
                                />
                            )}
                        />
                    </FormField>
                    <FormField>
                        <Controller
                            name="billing.billingLine1"
                            control={form.control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState,
                            }) => (
                                <TextInput
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    autoComplete="on"
                                    required
                                    placeholder="Gatuadress"
                                    validation={getValidationResultFromFormError(
                                        fieldState
                                    )}
                                />
                            )}
                        />
                    </FormField>
                    <FormFieldSet>
                        <FormField halfSize>
                            <Controller
                                name="billing.billingPostalCode"
                                control={form.control}
                                render={({
                                    field: { onChange, onBlur, value },
                                    fieldState,
                                }) => (
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        autoComplete="on"
                                        required
                                        type="number"
                                        placeholder="Postnummer"
                                        validation={getValidationResultFromFormError(
                                            fieldState
                                        )}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField halfSize>
                            <Controller
                                name="billing.billingTown"
                                control={form.control}
                                render={({
                                    field: { onChange, onBlur, value },
                                    fieldState,
                                }) => (
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        autoComplete="on"
                                        required
                                        placeholder="Ort"
                                        validation={getValidationResultFromFormError(
                                            fieldState
                                        )}
                                    />
                                )}
                            />
                        </FormField>
                    </FormFieldSet>
                    <FormFieldSet>
                        <FormField halfSize>
                            <Controller
                                name="billing.billingPhone"
                                control={form.control}
                                render={({
                                    field: { onChange, onBlur, value },
                                    fieldState,
                                }) => (
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        autoComplete="on"
                                        required
                                        placeholder="Mobilnummer"
                                        validation={getValidationResultFromFormError(
                                            fieldState
                                        )}
                                    />
                                )}
                            />
                        </FormField>
                    </FormFieldSet>
                </>
            )}

            {watchPaymentInfoId === "matkonto" &&
                !(
                    lastSavedDetails?.paymentInfo &&
                    isCustomerMatkontoPayment(lastSavedDetails.paymentInfo) &&
                    lastSavedDetails.paymentInfo.paymentToken
                ) &&
                !isNewForm && (
                    <FormField>
                        <Button
                            onClick={onMatkontoInitialize}
                            title="Authorize Matkonto"
                        />
                    </FormField>
                )}

            {watchPaymentInfoId === "matkonto" &&
                lastSavedDetails?.paymentInfo &&
                isCustomerMatkontoPayment(lastSavedDetails.paymentInfo) &&
                lastSavedDetails.paymentInfo.paymentToken && (
                    <FormFieldSet center>
                        <FormField threeQuarterSize>
                            <TextInput
                                value={
                                    lastSavedDetails.paymentInfo.paymentToken
                                        .maskedCardNumberNumber
                                }
                                placeholder="Matkonto kortnummer"
                                disabled
                            />
                        </FormField>
                        <FormField>
                            <Button
                                theme="inverted"
                                title="Ta bort"
                                onClick={() => {
                                    if (
                                        lastSavedDetails?.paymentInfo &&
                                        isCustomerMatkontoPayment(
                                            lastSavedDetails.paymentInfo
                                        ) &&
                                        lastSavedDetails.paymentInfo
                                            .paymentToken
                                    ) {
                                        onMatkontoDelete?.(
                                            lastSavedDetails.paymentInfo
                                                .paymentToken.id
                                        );
                                    }
                                }}
                            />
                        </FormField>
                    </FormFieldSet>
                )}

            <CellHeading>Övrigt</CellHeading>
            {isInvoicePayment(watchPaymentInfoId) && (
                <>
                    <FormField>
                        <Controller
                            name="billing.emailInvoice"
                            control={form.control}
                            render={({ field: { onChange, value } }) => (
                                <Checkbox
                                    value={value}
                                    onChange={(v) =>
                                        onChange(v.currentTarget.checked)
                                    }
                                    text="Fakturor till e-postadress"
                                />
                            )}
                        />
                    </FormField>
                    {watchEmailInvoice && (
                        <FormField>
                            <Controller
                                name="billing.billingEmail"
                                control={form.control}
                                render={({
                                    field: { onChange, onBlur, value },
                                    fieldState,
                                }) => (
                                    <TextInput
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        autoComplete="on"
                                        required
                                        placeholder="E-postadress för fakturor"
                                        validation={getValidationResultFromFormError(
                                            fieldState
                                        )}
                                    />
                                )}
                            />
                        </FormField>
                    )}
                </>
            )}

            <FormField>
                <Controller
                    name="helpToUnpack"
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            value={value}
                            onChange={onChange}
                            text="Behöver hjälp med upplockning av varor"
                        />
                    )}
                />
            </FormField>
            <FormField>
                <Controller
                    name="selfOrder"
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            value={value}
                            readOnly={lastSavedDetails?.selfOrder} // if it was enabled once, it cannot be disabled
                            onChange={(v) => onChange(v.currentTarget.checked)}
                            text="Kan handla själv"
                        />
                    )}
                />
            </FormField>
            {watchSelfOrder && (
                <>
                    <FormField>
                        <Controller
                            name="registrationEmail"
                            control={form.control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState,
                            }) => (
                                <TextInput
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    required
                                    readOnly={lastSavedDetails?.selfOrder} // if it was enabled once, it cannot be disabled
                                    placeholder="Brukarens e-postadress"
                                    validation={getValidationResultFromFormError(
                                        fieldState
                                    )}
                                />
                            )}
                        />
                    </FormField>
                </>
            )}
            {props.enableFormDevTool && <DevTool control={form.control} />}
        </>
    );
};

export default CustomerDeliveryForm;
