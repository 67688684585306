import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { dimension, fontSize, space } from "../common/variables";
import { NavigationItem } from "../views/navigationItems";

const Navigation = ({
    items,
    visible,
}: {
    items: NavigationItem[];
    visible: boolean;
}) => {
    return (
        <Wrapper visible={visible}>
            <List>
                {items.map((item, i) => (
                    <Item key={i}>
                        <NavLink
                            to={item.path}
                            style={({ isActive }) => {
                                if (isActive) {
                                    return {
                                        color: colors.green,
                                    };
                                }
                                return undefined;
                            }}>
                            {item.title}
                        </NavLink>
                    </Item>
                ))}
            </List>
        </Wrapper>
    );
};

export default Navigation;

const Wrapper = styled.nav`
    height: ${dimension.navHeight};
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -${dimension.navHeight};
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.border};
    transition: transform ${mixins.transitions.default};

    ${({ visible }: { visible: boolean }) =>
        visible
            ? css`
                  transform: translateY(0);
                  border-bottom: 1px solid ${colors.border};
              `
            : css`
                  transform: translateY(-${dimension.navHeight});
                  border-bottom: none;
              `}
`;

const List = styled.ul`
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const Item = styled.li`
    margin: 0 ${space.xSmall};
    font-size: ${fontSize.small};
`;

const Link = styled(NavLink)`
    color: ${colors.grayDarker};

    &:hover {
        color: ${colors.black};
    }
`;
