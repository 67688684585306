import { telemetry } from "../telemetry/TelemetryService";

export const Logger = {
    log: (param: any) => {
        console.log(param);
        telemetry.trackInfo(param);
    },
    error: (param: string | Error | any) => {
        console.error(param);
        if (param instanceof Error) {
            telemetry.trackError(param);
            return;
        }

        if (typeof param === "string") {
            telemetry.trackError(new Error(param));
            return;
        }

        telemetry.trackError(new Error("Unknown error"), param);
    },
};
