import { z } from "zod";
import { PaymentCodesArray } from "../views/CustomerView/customerTypes";
import { TimeRestrictionDayOfWeek } from "./types";
import {
    digitsOnlyInvalidMessage,
    digitsOnlyPattern,
    emailInvalidMessage,
    getIsFieldRequiredMessage,
    isNoDigitsPattern,
    medmeraIdInvalidMessage,
    mobileNrPattern,
    mobileNumberInvalidMessage,
    noDigitsInvalidMessage,
    postalCodeInvalidMessage,
    postalCodePattern,
    ssnInvalidMessage,
    ssnPattern,
} from "./validations";

const emailRequiredMessage = getIsFieldRequiredMessage("E-postadress");
export const emailSchema = z.union([
    // https://github.com/colinhacks/zod/issues/2513#issuecomment-1732405993
    z.literal(""),
    z
        .string({
            required_error: emailRequiredMessage,
        })
        .trim()
        .min(1, { message: emailRequiredMessage })
        .email({ message: emailInvalidMessage }),
]);

const firstNameRequiredMessage = getIsFieldRequiredMessage("Förnamn");
export const firstNameSchema = z
    .string({
        required_error: firstNameRequiredMessage,
    })
    .trim()
    .min(1, { message: firstNameRequiredMessage })
    .regex(isNoDigitsPattern, { message: noDigitsInvalidMessage });

const lastNameRequiredMessage = getIsFieldRequiredMessage("Efternamn");
export const lastNameSchema = z
    .string({
        required_error: lastNameRequiredMessage,
    })
    .trim()
    .min(1, { message: lastNameRequiredMessage })
    .regex(isNoDigitsPattern, { message: noDigitsInvalidMessage });

const addressLineRequiredMessage = getIsFieldRequiredMessage("Gatuadress");
export const addressLineSchema = z
    .string({
        required_error: addressLineRequiredMessage,
    })
    .trim()
    .min(1, { message: addressLineRequiredMessage });

const postalCodeRequiredMessage = getIsFieldRequiredMessage("Postnummer");
export const postalCodeSchema = z
    .string({
        required_error: postalCodeRequiredMessage,
    })
    .trim()
    .min(1, { message: postalCodeRequiredMessage })
    .regex(postalCodePattern, { message: postalCodeInvalidMessage });

const mobilePhoneRequiredMessage = getIsFieldRequiredMessage("Mobilnummer");
export const mobilePhoneSchema = z
    .string({
        required_error: mobilePhoneRequiredMessage,
    })
    .trim()
    .min(1, { message: mobilePhoneRequiredMessage })
    .regex(mobileNrPattern, { message: mobileNumberInvalidMessage });

export const homePhoneSchema = z.string().regex(digitsOnlyPattern, {
    message: digitsOnlyInvalidMessage,
});

const townRequiredMessage = getIsFieldRequiredMessage("Ort");
export const townSchema = z
    .string({
        required_error: townRequiredMessage,
    })
    .trim()
    .min(1, { message: townRequiredMessage })
    .regex(isNoDigitsPattern, { message: noDigitsInvalidMessage });

export const ssnRequiredMessage = getIsFieldRequiredMessage("Personnummer");
export const ssnSchema = z
    .string({
        required_error: ssnRequiredMessage,
    })
    .trim()
    .min(1, { message: ssnRequiredMessage })
    .regex(ssnPattern, {
        message: ssnInvalidMessage,
    });

const unitRequiredMessage = getIsFieldRequiredMessage("Enhet");
export const unitSchema = z.object(
    { id: z.string(), name: z.string() },
    {
        required_error: unitRequiredMessage,
    }
);

const timeRestrictionsRequiredMessage =
    getIsFieldRequiredMessage("Leveranstid");
export const timeRestrictionsSchema = z
    .array(
        z.object({
            id: z.string(),
            name: z.string(),
            extraData: z.object({
                dayOfWeek: z.enum(TimeRestrictionDayOfWeek),
                startTime: z.string(),
                endTime: z.string(),
                replacementHint: z.boolean().optional(),
            }),
        }),
        {
            required_error: timeRestrictionsRequiredMessage,
        }
    )
    .min(1, { message: timeRestrictionsRequiredMessage });

export const medmeraIdSchema = z.union([
    // https://github.com/colinhacks/zod/issues/2513#issuecomment-1732405993
    z.literal(""),
    z.string().trim().length(7, { message: medmeraIdInvalidMessage }),
]);

const paymentRequiredMessage = getIsFieldRequiredMessage("Betalsätt");
export const allPaymentsSchema = z.enum(PaymentCodesArray, {
    required_error: paymentRequiredMessage,
});

export const invoicePaymentSchema = z.enum(["collectiveinvoice", "intrum"], {
    required_error: paymentRequiredMessage,
});

export const matkontoPaymentSchema = z.literal("matkonto");
