import React from "react";
import styled from "styled-components";
import {
    CustomerBillingDTO,
    CustomerDTO,
    CustomerOrderDTO,
    UpdateCustomerBillingRequest,
    UpdateCustomerRequestBody,
} from "../../api/apiTypes";
import colors from "../../common/colors";
import {
    CUSTOMER_DUPLICATE_EMAIL_MESSAGE,
    SSN_MMID_MISSMATCH,
} from "../../common/exceptions";
import {
    convertOrderStatus,
    isOrderCancellable,
} from "../../common/helpers/convertOrderStatus";
import { translatePaymentCode } from "../../common/helpers/convertPaymentMethod";
import {
    safelyParseISO8601extendedFormat,
    toFriendlyDateString,
} from "../../common/helpers/dateHelpers";
import {
    capitalizeFirstCharacter,
    stringIsEmpty,
} from "../../common/helpers/stringHelpers";
import { PaymentTypes } from "../../common/translations";
import {
    FieldError,
    HybrisFieldError,
    HybrisFieldValidationResponse,
    TimeRestriction,
} from "../../common/types";
import { isHybrisValidationError } from "../../common/validations";
import { fontSize } from "../../common/variables";
import Button from "../../components/Button";
import { TableRowItem } from "../../components/Table/tableHelpers";
import Tag from "../../components/Tag";
import { CustomerFormData } from "./CustomerView.hooks";
import {
    Customer,
    CustomerListingItem,
    CustomerListingOrder,
    PaymentCodes,
} from "./customerTypes";

export interface CustomerRowDataType extends CustomerListingItem {
    id: string;
}

export const getOrderStatus = (orders: CustomerListingOrder[]) => {
    const sortedOrders = [...orders]
        .sort((a, b) => {
            return (
                new Date(
                    safelyParseISO8601extendedFormat(a.created)
                ).getTime() -
                new Date(safelyParseISO8601extendedFormat(b.created)).getTime()
            );
        })
        .reverse();

    const pendingOrder = sortedOrders.find(
        (order) => convertOrderStatus(order.status) !== "Levererad"
    );

    if (!pendingOrder) {
        return {
            sortPredicate: "",
            element: undefined,
        };
    }

    let placedOrder = pendingOrder.created;

    placedOrder = safelyParseISO8601extendedFormat(pendingOrder.created);

    const formattedDate = new Date(
        new Date(placedOrder).getTime() -
            new Date(placedOrder).getTimezoneOffset() * 60000
    )
        .toISOString()
        .split("T")[0];

    return {
        sortPredicate: formattedDate,
        element: (
            <Tag
                theme={
                    convertOrderStatus(pendingOrder.status) === "Makulerad"
                        ? "red-light"
                        : "green-light"
                }>
                <strong>{convertOrderStatus(pendingOrder.status)}</strong>
                <p>{formattedDate}</p>
            </Tag>
        ),
    };
};

export const customerOrdersRowData = (
    data: CustomerOrderDTO[],
    onCancelOrder?: (orderId: string) => void
    // callback: (customerId?: string) => void
) =>
    data.map((order) => {
        let placedOrder = order.placed;
        let deliveryOrder = order.actualDeliveryDate;

        const orderDate = toFriendlyDateString(placedOrder);
        const deliveryDate = toFriendlyDateString(deliveryOrder);

        return {
            data: order,
            rowValues: [
                {
                    sortPredicate: `${placedOrder}`,
                    value: orderDate,
                },
                {
                    sortPredicate: `${deliveryOrder}`,
                    value: deliveryDate,
                },
                // {
                //     sortPredicate: `${order.timeSlotTime}`,
                //     value: `${order.timeSlotTime}`
                // },
                {
                    sortPredicate: `${convertOrderStatus(order.status)}`,
                    value: `${convertOrderStatus(order.status)}`,
                },
                {
                    sortPredicate: `${order.code}`,
                    value: `${order.code}`,
                },
                {
                    value: isOrderCancellable(order.status) ? (
                        <Button
                            size="small"
                            theme="inverted"
                            preventDefault
                            onClick={() =>
                                onCancelOrder && onCancelOrder(order.code)
                            }
                            title="Makulera"
                            //disabled={!!!customer?.data.paymentInfo}
                        />
                    ) : (
                        ""
                    ),
                },
            ],
        } as TableRowItem<CustomerOrderDTO>;
    });

// remove c/o address when displaying the data in the form and also when setting lastSavedDetails state
export const formatLine2FromOutput = (s: string) => {
    return s
        .trim()
        .replaceAll("C/O", "")
        .replaceAll("c/o", "")
        .replaceAll("C/o", "")
        .replaceAll("c/O", "")
        .trim();
};

// remove user inputed c/o when saving, and add prefix C/O
export const formatLine2ForInput = (s: string) => {
    if (s.trim().length === 0) {
        return "";
    }

    return (
        "c/o " +
        s
            .replaceAll("C/O", "")
            .replaceAll("c/o", "")
            .replaceAll("C/o", "")
            .replaceAll("c/O", "")
            .trim()
    );
};

/**
 * Converts Customer model from Hybris DTO into domain model
 */
export const mapCustomerFromDTO = (data: {
    customer: CustomerDTO;
    restrictions?: TimeRestriction[];
    billingAddress?: CustomerBillingDTO;
}): Customer => ({
    id: data.customer.uid,
    firstName: data.customer.firstName || "",
    lastName: data.customer.lastName || "",
    line1: data.customer.defaultAddress?.line1 || "",
    registrationEmail: data.customer.municipalEmail || "",
    postalCode: data.customer.defaultAddress?.postalCode || "",
    town: data.customer.defaultAddress?.town || "",
    phone: data.customer.defaultAddress?.phone || "",
    homePhone: data.customer.defaultAddress?.homePhone || "",
    orderPickerMessage: data.customer.orderPickerMessage || "",
    defaultDriverMessage: data.customer.defaultDriverMessage || "",
    helpToUnpack: data.customer.helpToUnpack || false,
    selfOrder: data.customer.selfOrder || false,
    doorCode: data.customer.defaultAddress?.doorCode || "",
    email: data.customer.defaultAddress?.email || "",
    socialSecurityNumber: data.customer.socialSecurityNumber || "",
    medmeraId: data.customer.medmeraId || "",
    billing: data.billingAddress
        ? {
              billingId: data.billingAddress.id,
              billingFirstName: data.billingAddress.firstName,
              billingLastName: data.billingAddress.lastName,
              billingLine1: data.billingAddress.line1,
              billingLine2:
                  data.billingAddress.line2 &&
                  formatLine2FromOutput(data.billingAddress.line2),
              billingPostalCode: data.billingAddress.postalCode,
              billingTown: data.billingAddress.town,
              billingPhone: data.billingAddress.phone,
              billingReference: data.billingAddress.reference,
              billingEmail: data.billingAddress.email,
              emailInvoice: data.customer.emailInvoice || false,
          }
        : undefined,
    timeRestrictions: !!data.restrictions?.length
        ? data.restrictions
        : undefined,
    paymentInfo:
        data.customer.paymentInfo &&
        mapCustomerPaymentFromDTO(data.customer.paymentInfo),

    unit: data.customer.municipalityUnitCode
        ? {
              id: data.customer.municipalityUnitCode,
              name: data.customer.municipalityUnitName || "",
          }
        : undefined,
});

const mapCustomerPaymentFromDTO = (
    data: Exclude<CustomerDTO["paymentInfo"], undefined>
): Customer["paymentInfo"] => {
    if (data.code === "matkonto") {
        return {
            id: data.code,
            name: "Matkonto",
            paymentToken: data.paymentToken && {
                id: data.paymentToken.id,
                cardBrand: data.paymentToken.cardBrand,
                expiryDate: data.paymentToken.expiryDate,
                maskedCardNumberNumber:
                    data.paymentToken.maskedCardNumberNumber,
            },
        };
    }

    return {
        id: data.code,
        name: translatePaymentCode(data.code) as Exclude<
            PaymentTypes,
            "Matkonto"
        >,
    };
};

export const mapCustomerFormToUpdateRequest = (
    data: CustomerFormData
): UpdateCustomerRequestBody => {
    const result: UpdateCustomerRequestBody = {
        firstName: data.firstName,
        lastName: data.lastName,
        postalCode: data.postalCode,
        email: data.email || "",
        town: data.town,
        phone: data.phone,
        homePhone: data.homePhone || "",
        line1: data.line1,
        line2: "",
        updatedMUCode: data.unit?.id || "",
        orderPickerMessage: data.orderPickerMessage,
        defaultDriverMessage: data.defaultDriverMessage,
        doorCode: data.doorCode,
        helpToUnpack: data.helpToUnpack,
        emailInvoice:
            isCustomerPaymentInvoice(data) && data.billing.emailInvoice,
        isSelfOrder: data.selfOrder,
        titleCode: "Mr",
        municipalityPhone: "",
        medmeraId: data.medmeraId,
    };

    if (data.selfOrder) {
        result.registrationEmail = data.registrationEmail;
    }

    if (isCustomerPaymentInvoice(data)) {
        result.socialSecurityNumber = data.socialSecurityNumber;
    }
    return result;
};

export const mapCustomerFormToUpdateBillingRequest = (
    data: CustomerFormData
): UpdateCustomerBillingRequest | null => {
    if (!isCustomerPaymentInvoice(data)) {
        return null;
    }

    const result: UpdateCustomerBillingRequest = {
        firstName: data.billing.billingFirstName || "",
        lastName: data.billing.billingLastName || "",
        postalCode: data.billing.billingPostalCode || "",
        town: data.billing.billingTown || "",
        phone: data.billing.billingPhone || "",
        line1: data.billing.billingLine1 || "",
        line2: formatLine2ForInput(data.billing.billingLine2 || ""),
        reference: data.billing.billingReference || "",
        emailInvoice: data.billing.emailInvoice || false,
    };
    if (data.billing.emailInvoice) {
        result.email = data.billing.billingEmail;
    }
    return result;
};

// When manipulating billing address data, for any error that Hybris returns map it to the data structure that is used in the billing address form
// i.e. firstName -> billing.billingFirstName
// "billing." prefix is added so that React Hook Forms can correctly set the error message to the correct field
export const mapHybrisCustomerBillingErrorField = (
    args: HybrisFieldError
): { fieldName: string; message: string } => {
    // if the error is related to the registration field, return the field name as it is - dont add "billing" to it
    if (args.subject === "registrationEmail") {
        return {
            fieldName: args.subject,
            message: args.message,
        };
    }

    return {
        fieldName: `billing.billing${capitalizeFirstCharacter(args.subject)}`,
        message: args.message,
    };
};

/**
 * Hybris returns errors with different models
 * This function is to map known exceptions to the form field names
 */
export const mapHybrisCustomerInfoErrorField = (
    args: HybrisFieldError
): { fieldName: string; message: string } => {
    if (args.message === CUSTOMER_DUPLICATE_EMAIL_MESSAGE) {
        return {
            fieldName: "registrationEmail",
            message: "E-postadressen finns redan registrerad",
        };
    }

    if (
        args.subject === "email" &&
        args.message === "address.email.forbidden"
    ) {
        return {
            fieldName: "email",
            message: "Ange en annan e-postadress",
        };
    }

    return { fieldName: args.subject, message: "" };
};

export const extractFieldErrorsFromHybrisErrorAsArray = (
    errorBody: HybrisFieldValidationResponse,
    mapErrorFromHybris: (args: HybrisFieldError) => {
        fieldName: string;
        message: string;
    }
) => {
    const fieldErrors: FieldError[] = [];
    errorBody.errors.forEach((el) => {
        const fieldError = mapErrorFromHybris(el);
        fieldErrors.push({
            fieldName: fieldError.fieldName,
            message: fieldError.message || "Felaktigt format",
            isDirty: true,
            isValid: false,
        });
    });

    return fieldErrors;
};

const isResponseWithStoreEmailError = (
    response: HybrisFieldValidationResponse
) => {
    const emailError = response.errors?.find(
        (error) => error.subject === "email"
    );
    if (!emailError) {
        return false;
    }

    if (emailError.message !== "address.email.forbidden") {
        return false;
    }

    return true;
};

export const getModalCustomerErrorText = (response: any) => {
    if (!isHybrisValidationError(response)) {
        return undefined;
    }

    if (isResponseWithStoreEmailError(response)) {
        return "Det ser ut som att du har fyllt i en e-post som inte bör vara fakturamottagare. Vänligen skriv in den adress som fakturan ska skickas till.";
    }

    if (response.errors.find((error) => error.reason === SSN_MMID_MISSMATCH)) {
        return "Ifyllt Medmera-ID och personnummer har ingen koppling. Vänligen lämna fältet för Medmera-ID tomt så hämtar vi upp rätt ID.";
    }

    if (
        response.errors.find(
            (error) => error.message === CUSTOMER_DUPLICATE_EMAIL_MESSAGE
        )
    ) {
        return "En användare med den e-postadressen du uppgav existerar redan.";
    }

    return undefined;
};

export interface HandlaProxyValidation {
    isValid: boolean;
    errors: {
        message: string;
        section: "none" | "default" | "billing" | "blocked";
        action: "Välj" | "Fyll i" | null;
    }[];
}

export const validateHandlaProxyButton = (
    customer?: Customer,
    rawCustomer?: CustomerDTO
): HandlaProxyValidation => {
    const response: HandlaProxyValidation = {
        isValid: true,
        errors: [],
    };

    if (!customer || !rawCustomer) {
        return {
            isValid: false,
            errors: [],
        };
    }

    // customer fields
    if (stringIsEmpty(customer.firstName)) {
        response.errors.push({
            message: "Förnamn",
            section: "default",
            action: "Fyll i",
        });
    }
    if (stringIsEmpty(customer.lastName)) {
        response.errors.push({
            message: "Efternamn",
            section: "default",
            action: "Fyll i",
        });
    }
    if (stringIsEmpty(customer.line1)) {
        response.errors.push({
            message: "Adress",
            section: "default",
            action: "Fyll i",
        });
    }
    if (stringIsEmpty(customer.postalCode)) {
        response.errors.push({
            message: "Postnummer",
            section: "default",
            action: "Fyll i",
        });
    }
    if (stringIsEmpty(customer.town)) {
        response.errors.push({
            message: "Stad",
            section: "default",
            action: "Fyll i",
        });
    }
    if (stringIsEmpty(customer.phone)) {
        response.errors.push({
            message: "Mobilnummer",
            section: "default",
            action: "Fyll i",
        });
    }

    // Time windows
    if (
        customer.timeRestrictions === undefined ||
        customer.timeRestrictions === null ||
        customer.timeRestrictions.some((item) => stringIsEmpty(item.startTime))
    ) {
        response.errors.push({
            message: "Tidsfönster",
            section: "billing",
            action: "Välj",
        });
    }

    // Unit
    if (
        customer.unit === undefined ||
        customer.unit === null ||
        stringIsEmpty(customer.unit.id)
    ) {
        response.errors.push({
            message: "Enhet",
            section: "billing",
            action: "Välj",
        });
    }

    // Payment info
    if (customer.paymentInfo === undefined || customer.paymentInfo === null) {
        response.errors.push({
            message: "Betalningsmetod",
            section: "billing",
            action: "Välj",
        });
    }

    // Blocked in Hybris BO
    if (
        rawCustomer &&
        rawCustomer.blockedForMunicipalityShopping &&
        rawCustomer.blockedForMunicipalityShoppingReason
    ) {
        response.errors.push({
            message: rawCustomer.blockedForMunicipalityShoppingReason,
            section: "blocked",
            action: null,
        });
    }

    // billing fields
    if (customer.paymentInfo && customer.paymentInfo.id === "intrum") {
        if (
            customer.billing &&
            stringIsEmpty(customer.billing.billingFirstName)
        ) {
            response.errors.push({
                message: "Förnamn",
                section: "billing",
                action: "Fyll i",
            });
        }
        if (
            customer.billing &&
            stringIsEmpty(customer.billing.billingLastName)
        ) {
            response.errors.push({
                message: "Efternamn",
                section: "billing",
                action: "Fyll i",
            });
        }
        if (customer.billing && stringIsEmpty(customer.billing.billingLine1)) {
            response.errors.push({
                message: "Adress",
                section: "billing",
                action: "Fyll i",
            });
        }
        if (
            customer.billing &&
            stringIsEmpty(customer.billing.billingPostalCode)
        ) {
            response.errors.push({
                message: "Postnummer",
                section: "billing",
                action: "Fyll i",
            });
        }
        if (customer.billing && stringIsEmpty(customer.billing.billingTown)) {
            response.errors.push({
                message: "Stad",
                section: "billing",
                action: "Fyll i",
            });
        }
        if (customer.billing && stringIsEmpty(customer.billing.billingPhone)) {
            response.errors.push({
                message: "Mobilnummer",
                section: "billing",
                action: "Fyll i",
            });
        }
        if (
            customer.billing &&
            customer.billing.emailInvoice &&
            stringIsEmpty(customer.billing.billingEmail)
        ) {
            response.errors.push({
                message: "E-post",
                section: "billing",
                action: "Fyll i",
            });
        }
    }

    response.isValid = response.errors.length === 0 ? true : false;
    return response;
};

export const formatSSN = (ssn: string) => {
    // if frist part is YYYY cut 2 frist digits
    const cutFromStart = ssn.length >= "YYYYMMDDNNNN".length ? 2 : 0;
    // cut NNNN from the end
    const cutFromEnd = ssn.length - 4;
    const addDivider = ssn.indexOf("-") === -1;

    let result = ssn.substring(cutFromStart, cutFromEnd);
    if (addDivider) {
        result += "-";
    }

    return result.padEnd(ssn.length - cutFromStart + (addDivider ? 1 : 0), "X");
};

export const isInvoicePayment = (paymentName: PaymentCodes | undefined) => {
    return paymentName === "intrum" || paymentName === "collectiveinvoice";
};

export const isCustomerPaymentInvoice = (
    customer: CustomerFormData
): customer is CustomerFormData & { paymentInfoId: "intrum" } => {
    return isInvoicePayment(customer.paymentInfoId);
};

export const BlockedShoppingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    color: ${colors.red};

    line-height: ${fontSize.xSmall};
    font-size: ${fontSize.xSmall};
`;

export const BlockedShoppingIcon = styled.div`
    svg {
        fill: currentColor;
        height: 16px;
        width: 16px;
        margin-right: 10px;
    }
`;
